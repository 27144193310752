import lodash from 'lodash';
window._ = lodash;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
const axiosInstance= axios.create({
    headers: {
        // 'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    },
    withCredentials: true,
    withXSRFToken: true,
    // Do not follow blindly, we will set up our own relocation method
    maxRedirects: 0 // Sets fetch() redirect to "manual"
});

axiosInstance.jsonPatch = function(url, data, options) {
    for(let key in data) {
        let entry = data[key];

        if(!entry.op?.length ||
            !entry.path?.length
            // TODO extend the validations
        ) {
            console.error(`Payload has invalid format at index {key}`);
            console.log(entry);
            console.warn('Aborting patch request');

            return;
        }
    }

    return this.patch(url + '/json-patch', data, options);
};

window.axios = axiosInstance;

function setAxiosIntercreptor(Application) {
    axios.interceptors.request.use((config) => {
        if (!config.url) {
            return config; // Do nothing on developer errors
        }

        const separator = '/';
        if(config.urlParams) {
            let fragments = config.url.split(separator);
            fragments.forEach((fragment, fragmentIndex) => {
                for(let [value, key] in Object.entries(config.urlParams)) {
                    if(`:${key}` === fragment) {
                        fragments[fragmentIndex] = value;
                        break; // Prevent multiple replace
                    }
                }
            });
            config.url = fragments.join(separator);
        }

        return config;
    });

    // Add a response interceptor
    axios.interceptors.response.use(
        // In case of 2xx
        undefined, // Do nothing with it here
        // Outside 2xx status
        (error, x) => {
            if(!error.hasOwnProperty('response')) {
                console.error('Missing response in axios error. This interceptor is unable to handle it. Check if the provided request url is not empty! Trace:');
                console.trace();

                return Promise.reject(error);
            }

            switch(error.response.status) {
                case 302: // Transforming relocation order into a valid UI action instead of an error
                    if(error.response.data.accepted) {
                        /**
                         * Vue.js will not perform relocation two times if the original action would be
                         * a relocation too to the same url as in the Login component.
                         * Otherwise, double relocation may can happen.
                         */
                        Application.config.globalProperties.$router.replace({
                            path: (error.response.headers.get("content-type") === 'application/json') ?
                                error.response.data.relocate :
                                error.response.data
                        });

                        // This is not an error to be honest but a valid redirection response
                        return Promise.resolve(error.response);
                    }
                    break;
                case 401: // Authentication is required so take the user to the login page
                    let resolved = Application.config.globalProperties.$router.resolve({name: 'login'});
                    window.location.replace(resolved.href); // A regular router redirect is not enough
                    break;
            }

            return Promise.reject(error);
        }
    );
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import { createApp } from 'vue/dist/vue.esm-bundler';
import { createI18n } from 'vue-i18n';
import Cookie from "js-cookie";
import Configuration from "./plugins/Configuration.js";
import FormatterPlugin from "./plugins/Formatter";
import UserPlugin from "./plugins/User.js";
import BroadcastChannelPlugin from "./plugins/BroadcastChannel.js";
import ReverbChannelPlugin from "./plugins/ReverbChannel.js";
import * as VueRouter from "vue-router";
import {createPinia} from "pinia";
import Frame from "./pages/common/Frame.vue";
import * as BroadcastAbles from "./classes/Broadcastables.js";
import "./echo.js";
import {useResourceStore} from "./stores/resourceStore.js";
// import {has} from "lodash/object.js";
// import {inject} from "vue";

const env = {
    silentTranslationWarn: import.meta.env.VITE_VUEJS_I18N_SUPPRESS_WARNING_TRANSLATION?.toLowerCase() === 'true',
    silentFallbackWarn: import.meta.env.VITE_VUEJS_I18N_SUPPRESS_WARNING_FALLBACK?.toLowerCase() === 'true'
};

let currentLocale = window.frontendConfiguration.vueJs.localization.current ?? 'en';

const I18n = createI18n({
    fallbackLocale: window.frontendConfiguration.vueJs.localization.fallback ?? 'en',
    silentTranslationWarn: env.silentTranslationWarn,
    silentFallbackWarn: env.silentFallbackWarn,
    formatFallbackMessages: true
});

export async function setApplicationDefaults(Application, routes) {
    const App = Application ?? createApp({
        components: { Frame },
        template: '<Frame/>',

        data() {return {
            defaultBroadcastListenerId: null
        };},

        async beforeMount() {
            this.$configuration.setLocale(currentLocale);

            this.defaultBroadcastListenerId = this.$broadcast.add((bag, event) => {
                switch(true) {
                    case bag instanceof BroadcastAbles.LoginEvent:
                    case bag instanceof BroadcastAbles.LogoutEvent:
                        window.location = bag.data;
                        break;
                }
            });
        },

        beforeUnmount() {
            this.$broadcast.remove(this.defaultBroadcastListenerId);
        },

        mounted() {
            new BroadcastAbles.ApplicationMounted().dispatch();
        }
    });

    App.provide('Application', App);

    App.use(createPinia());

    setAxiosIntercreptor(App);

    const Router = new VueRouter.createRouter({
        history: VueRouter.createWebHistory(),
        routes: routes
    });

    const User = new UserPlugin();

    const getRouterGuardInjections = (to, from) => {
        const Application = App;
        return {
            // Application: inject('Application'),
            Application,
            to,
            from
        }
    };

    Router.beforeEach(async (to, from, next) => {
        for(let route of to.matched ?? []) {
            if(await route.meta.beforeEach?.(getRouterGuardInjections(to, from)) === false) {
                return false; // Cancels the navigation
            }
        }

        next();
    });

    Router.beforeResolve(async (to, from) => {
        const resourceStore = useResourceStore();
        let hasPermission = true;
        let hasRole = true;

        await to.meta.beforeResolve?.(getRouterGuardInjections(to, from));

        for(let key in to.matched) {
            let Route = to.matched[key];

            if(Route.meta.permissions?.length) {
                let result = resourceStore.getCurrentUser().hasPermission(Route.meta.permissions, {to, from, Application: App});
                let resolvedResult = (result instanceof Promise) ? await result : result;

                if(!resolvedResult) {
                    hasPermission = false;
                }
            }

            if(Route.meta.roles?.length) {
                let result = resourceStore.getCurrentUser().hasRole(Route.meta.roles, {to, from, Application: App});
                let resolvedResult = (result instanceof Promise) ? await result : result;

                if(!resolvedResult) {
                    hasRole = false;
                }

            }

            if(!hasPermission || !hasRole) {
                break;
            }
        }

        if(!hasPermission || !hasRole) {
            return {name: 'http.status.403'};
        }

        to.meta.afterResolved?.(getRouterGuardInjections(to, from));

        return true;
    });

    Router.afterEach(async (to, from, failure) => {
        await to.meta.afterEach?.(getRouterGuardInjections(to, from));
    });

    const Config = new Configuration(I18n);

    let cookieName = Config.get('vueJs.localization.cookieName');
    if(cookieName) {
        let cookieValue = Cookie.get(cookieName);

        if(cookieValue) {
            Config.get('vueJs.localization.allowed').hasOwnProperty(cookieValue) ?
                Cookie.set(cookieName, cookieValue, { expires: 400 }) :
                Cookie.remove(cookieName);
        }
        else {
            Cookie.set(cookieName, currentLocale, { expires: 400 });
        }
    }

    App
        .use(I18n)
        .use(Config)
        .use(User)
        .use(new FormatterPlugin)
        .use(new BroadcastChannelPlugin)
        .use(new ReverbChannelPlugin)
        .use(Router);
    //document.documentElement.setAttribute('lang', Config.global.locale);

    return App;
}

export { I18n };
