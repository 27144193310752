<template>
    <div class="frame-common" :class="getMainFrameClasses">
        <header class="frame-common__header">
            <div role="button" class="frame-common__header__nav-control" :class="getNavigationClasses" @click="toggleNavigationEvent"></div>
            <div class="frame-common__header__title">
                <router-link :to="{name: 'index'}">{{ $t('header.title') }}</router-link>
            </div>
            <div role="button" class="frame-common__header__settings-control" :class="getSettingsClasses" @click="toggleSettingsEvent"></div>
        </header>
        <div class="frame-common__content">
            <div class="frame-common__content__settings" :class="getSettingsClasses">
                <LanguageSelector/>
                <ThemeSelector/>
                <router-link class="button" v-if="$user.isLoggedIn()" :to="{name: 'logout'}">{{$t('navigation.logout')}}</router-link>
                <Button @click="closeSettings">{{ $t('html.labels.close') }}</Button>
            </div>
            <div class="frame-common__content__navigation" :class="getNavigationClasses">
                <Navigation @navigation="closeNavigation"/>
            </div>
            <Scrollable class="frame-common__content__view">
                <router-view v-if="!$user.isLoggedIn()" v-slot="{ Component }">
                    <Transition>
                        <component :is="Component" :class="getContentViewClasses" class="page"/>
                    </Transition>
                </router-view>
                <router-view v-else :class="getContentViewClasses" class="page"></router-view>
            </Scrollable>
        </div>
        <div class="frame-common__header__modals">
            <Modal v-for="(entry, index) in confirmModals" @modal:cancel="cancelConfirmModalEvent(index)">
                <template v-if="entry.title" #header-title>{{entry.title}}</template>
                <template v-if="entry.body" #body>
                    <component v-if="confirmModalItemIsComponent(entry.body)" :is="entry.body"/>
                    <template v-else>{{entry.body}}</template>
                </template>
                <template v-if="entry.confirm" #footer-other>
                    <Button @click="confirmConfirmModalEvent(index)">{{$t('html.forms.yes')}}</Button>
                </template>
            </Modal>
        </div>
        <footer>
            <CookieReminder/>
        </footer>
    </div>
</template>

<script>
import LanguageSelector from "../../components/LanguageSelector.vue";
import Navigation from "../../components/Navigation.vue";
import ThemeSelector from "../../components/ThemeSelector.vue";
import {useThemeStore} from "../../stores/themeStore.js";
import {Scrollable} from "@jensarray/library";
import Button from "../../components/buttons/Button.vue";
import CookieReminder from "../../components/CookieReminder.vue";
import LinkToGuild from "../../components/buttons/Guilds/LinkToGuild.vue";
import Modal from "../../components/Modal.vue";

export default {
    components: {LinkToGuild, CookieReminder, Button, Navigation, LanguageSelector, ThemeSelector, Scrollable, Modal},

    i18n: {
        messages: {
            en: {
                header: {
                    title: 'Reenactment'
                },
            },
            hu: {
                header: {
                    title: 'Hagyományőrség'
                },
            },
        }
    },

    data() {return {
        ThemeStore: useThemeStore(),
        displayNavigation: false,
        displaySettings: false,
        confirmModals: []
    };},

    provide() {return {
        openConfirmModal: this.openConfirmModal
    }},

    methods: {
        openNavigation() {
            this.displayNavigation = true;
        },

        closeNavigation() {
            this.displayNavigation = false;
        },

        openSettings() {
            this.displaySettings = true;
        },

        closeSettings() {
            this.displaySettings = false;
        },

        toggleNavigationEvent() {
            this.closeSettings();
            this.displayNavigation ? this.closeNavigation() : this.openNavigation();
        },

        toggleSettingsEvent() {
            this.closeNavigation();
            this.displaySettings ? this.closeSettings() : this.openSettings();
        },

        openConfirmModal(options) {
            this.confirmModals.push(options);
        },

        cancelConfirmModalEvent(index) {
            this.confirmModals[index]?.cancel?.();

            this.removeConfirmModal(index);
        },

        confirmConfirmModalEvent(index) {
            this.confirmModals[index]?.confirm?.();

            this.removeConfirmModal(index);
        },

        removeConfirmModal(index) {
            this.confirmModals.splice(index, 1);
        },

        confirmModalItemIsComponent(entry) {
            return (typeof entry === 'object') && (entry.render || entry.template);
        }
    },

    computed: {
        getMainFrameClasses() {
            return [
                this.$user.isLoggedIn() ? '-user' : '-guest',
                this.ThemeStore.getHtmlClass()
            ];
        },

        getContentViewClasses() {
            return this.getMainFrameClasses;
        },

        getNavigationClasses() {
            return [
                this.displayNavigation ? '-opened' : '-closed'
            ];
        },

        getSettingsClasses() {
            return [
                this.displaySettings ? '-opened' : '-closed'
            ];
        }
    }
}
</script>
